.store {
  background-color: #FFFFFF;
  padding: 20px 20px 20px 20px;
  border-radius: 20px;
  font-size: 17px;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.store-title {
  font-size: 19px;
  font-style: italic;
  font-weight: 800;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  align-self: center;
  color: #3e2a94;
}

.store-cta {
  width: 100%;
  line-height: 1.4em;
  font-weight: 800;
  font-size: 19px;
  color: #3e2a94;
  margin-top: 0px;
  margin-bottom: 10px;
}

.store-image {
  width: 100%;
  margin: 20px;
  margin-left: 0px;
  align-self: center;
}

.store-link {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.store-link-content {
  background-color: #312272;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 40%;
}

.store-link-content:hover {
  background-color: #a26bff;
}

@media only screen and (min-width:1026px) {
  .store {
    width: 50%;
  }
}

@media only screen and (min-width: 1400) {
  .store-title {
    font-size: 24px;
  }

  .store-cta {
    font-size: 25px;
  }

  .store-link-content {
    font-size: 18px;
  }
}