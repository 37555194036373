.glossary {
  padding-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 40px;
  margin-right: 40px;
  color: #FFFFFF;
}

.glossary p {
  text-align: initial;
  margin-top: 0px;
}

.glossary h2 {
  margin-bottom: 8px;
}