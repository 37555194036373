@media only screen and (min-width: 768px) {
  .features {
    margin-top: -40px;
  }

  .features-featureItem-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}