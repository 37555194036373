.teamBio {
  margin: 20px 20px 20px 20px auto;
}

.teamBio-img {
  height: 200px;
  border-radius: 200px;
  margin-top: 40px;
}

.teamBio-teamMember {
  display: flex;
  flex-direction: column;
  background-color: #312272;
  padding: 20px;
  width: fit-content;
  border-radius: 8px 8px 20px 20px;
  margin: auto;
  margin-top: 20px;
}

.teamBio-teamMember-name {
  color: #FFFFFF;
  font-size: 26px;
}

.teamBio-teamMember-title {
  color: #FFFFFF;
  font-size: 21px;
}

.teamBio-content {
  color: #FFFFFF;
  margin-left: 20px;
  margin-right: 20px;
}

@media only screen and (min-width: 768px) {
  .teamBio-teamMember {
    margin-top: 30px;
  }
}