.vision {
  color: #FFFFFF;
}

.vision-text {
  font-size: 16px;
  text-align: center;
  line-height: 1.8em;
  font-weight: 600;
  margin: 0px 20px 20px 20px;
}

.vision-text-emphasis {
  color: #00FF85;
}

.vision-title {
  font-size: 26px;
  margin: 20px 20px 20px 20px;
}

.vision-image {
  height: 66vw;
  width: 90vw;
  margin: 20px 20px 20px 20px;
  border: 1px #A26BFF solid;
  border-radius: 20px;
  object-fit: cover;
}

.vision-link {
  display: flex;
  justify-content: space-evenly;
}

.vision-mascot {
  height: 220px;
  position: relative;
  bottom: 110px;
}

.vision-hr {
  width: 90%;
  background-color: #FFFFFF;
  margin: 8px 0px 8px 0px;
  margin-left: auto;
  margin-right: auto;
  height: 3px; 
}

@media only screen and (min-width: 768px) {
  .vision {
    position: relative;
  }
  
  .vision-title {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .vision-text {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .vision-image {
    max-width: 700px;
    max-height: 400px;
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .vision {
    top: calc(-15em + 15vw);
  }
}

@media only screen and (min-width: 1026px) and (orientation: portrait) {
  .vision {
    top: calc(-15em + 15vw);
  }
}

@media only screen and (min-width: 1166px) and (orientation: portrait) {
  .vision {
    top: calc(-18em + 15vw);
  }
}

@media only screen and (min-width: 1324px) and (orientation: portrait) {
  .vision {
    top: calc(-21em + 18vw);
  }
}

@media only screen and (min-width: 1498px) {
  .vision {
    top: -75px;
  }

  .vision-title {
    font-size: 50px;
  }

  .vision-text {
    font-size: 18px;
  }
}
