.main {
  padding-top: 94px;
}

.multimedia {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1040px;
}

@media only screen and (min-width: 1026px) {
  .multimedia {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
