.contact {
  display: flex;
  flex-direction: column;
  align-content: left;
  background-color: #FFFFFF;
  margin-bottom: 5vw;
  padding: 20px 20px 20px 20px;
  border-radius: 20px;
  font-size: 17px;
  font-weight: 800;
}

.contact-title {
  font-size: 23px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 80%;
  align-self: center;
}

.contact-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
}

.contact-instructions {
  line-height: 1.8em;
  text-align: left;
  font-weight: 500;
  font-size: 17px;
}

.contact-label {
  font-weight: 500;
}

.contact-image {
  height: 54px;
}

.contact-input {
  width: 98%;
  background-color: #FFFFFF;
  height: 60px;
  border: 1px solid black;
  border-radius: 14px;
  margin: 10px 0px 20px 0px;
}

.contact-input:hover {
  background-color: #a26bff;
}

.contact-submit {
  background-color: #312272;
  color: #FFFFFF;
  width: 100%;
  height: 60px;
  font-size: 17px;
  font-weight: 800;
  border-radius: 14px;
}

.contact-submit:hover {
  background-color: #a26bff;
}

@media only screen and (min-width: 768px) {
  .contact {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width:1026px) {
  .contact {
    margin-bottom: 0px;
    margin-right: 40px;
    width: 50%
  }

  .contact-social-wrapper {
    margin-top: auto;
  }
}

@media only screen and (min-width: 1400px) {
  .contact-title {
    font-size: 36px;
  }

  .contact-instructions {
    font-size: 18px;
  }

  .contact-form {
    font-size: 16px;
  }
}