.news {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 20px auto;
}

@media only screen and (min-width: 768px) {
  .news {
    flex-wrap: nowrap;
    position: relative;
    top: -12vh;
    height: 22vh;
    width: 86vw;
    margin-left: 7vw;
  }
}

@media only screen and (min-width: 1026px) {
  .news {
    top: -17vh;
  }
}

@media only screen and (min-width: 1166px) {
  .news {
    top: -21vh;
  }
}

@media only screen and (min-width: 1324px) {
  .news {
    top: -23vh;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1417px) {
  .news {
    width: 1134px;
    top: -13vw;
  }
}

@media only screen and (min-width: 1417px) {
  .news {
    top: -20vh;
  }
}