.social {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.social-hero {
  width: 200px;
}

.social-image {
  width: 11vw;
  height: 11vw;
  max-width: 60px;
  max-height: 60px;
  border-radius: 3px;
}

.social-link-bar {
  margin: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: auto;
}

.social-link {
  width: 100%;
  overflow: hidden;
  max-width: 120px;
  max-height: 60px;
}

.social-title {
  color: #FFFFFF;
  font-size: 22px;
  font-style: italic;
  font-weight: 800;
}

@media only screen and (min-width: 580px) {
  .social-link-bar {
    justify-content: center;
  }
}