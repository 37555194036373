.hero-image {
  background-image:  
    url('Fam1.jpeg');
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}

.hero-text {
  text-align: left;
  position: relative;
  top: -5%;
  margin-left: 20px;
  color: #FFFFFF;
  width: 70vw;
  font-size: 69px;
}

.hero-text-h1 {
  font-size: 48px;
  line-height: 1.2em;
  margin-top: 0px;
}

.hero-text-h1-primary {
  color: #FFFFFF;
}

.hero-text-h1-emphasis {
  color: #00FF85;
}

.hero-button {
  border: 0;
  background: transparent;
}

.hero-cta-image {
  width: 40px;
}

.hero-cta-text {
  width: 224px;
  height: 70px;
  background-color: #312272;
  color: #FFFFFF;
  font-size: 15px;
  letter-spacing: 0.25em;
  font-weight: 800;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 35px;
  padding-left: 20px;
}

.hero-cta {
  display:flex;
  flex-direction: row;
  align-items: flex-end;
}

@media only screen and (min-width: 768px) {
  .hero-image {
    height: 82vh;
    align-items: flex-start;
  }

  .hero-text {
    top: 5%;
    margin-left: 50px;
    margin-right: 50px;
    width: 85%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-size: 36px;
  }

  .hero-cta {
    margin-top: 9px;
  }

  .hero-text-h1 {
    margin-bottom: 0px;
    width: 62%;
    font-size: 36px;
  }
}

@media only screen and (min-width: 1026px) {
  .hero-text-h1 {
    width: 43%;
    font-size: 69px;
  }
}

@media only screen and (min-width: 1166px) {
  .hero-text-h1 {
    width: 68%;
  }

  .hero-text {
    margin-left: 6vw;
  }
}

@media only screen and (min-width: 1275px) {
  .hero-text-h1 {
    width: 62%;
  }
}

@media only screen and (min-width: 1391px) {
  .hero-text-h1 {
    width: 57%;
  }
}

@media only screen and (min-width: 1522px) {
  .hero-text-h1 {
    width: fit-content;
  }

  .hero-text {
    justify-content: space-evenly;
    width: 1454px;
    margin-left: auto;
    margin-right: auto;
    top: 15%;
  }
}