.sponsors {
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsors-cta {
  color: #00FFC0;
  background-color: #2F0549;
  padding: 20px;
  font-weight: 600;
  font-size: 15px;
}

.sponsors-cta:hover {
  color: #312272;
  background-color: #A26BFF;
}

.sponsors-cta-link {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: auto;
  bottom: 0px;
}

.sponsors-hr {
  width: 90%;
  background-color: #FFFFFF;
  margin: 8px 0px 8px 0px;
  margin-left: auto;
  margin-right: auto;
  height: 3px;
}

.sponsors-link {
  height: 60px;
  margin: 15px 0px 15px 0px;
}

.sponsor-logo {
  height: 60px;
}

.sponsors-logos-group {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0px 20px 0px 20px;
  padding-bottom: 10px;
}

.sponsors-title {
  color: #00FFC0;
  font-size: 21px;
  font-weight: 800;
  margin: 20px auto;
}

@media only screen and (min-width: 768px) {
  .sponsors-logos-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1300px;
  }

  .sponsors-link {
    margin: 15px 15px 15px 15px;
  }
}

@media only screen and (min-width: 1400px) {
  .sponsors-title {
    font-size: 30px;
  }
}