.featureItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  margin: 20px auto;
  width: 90vw;
  border-radius: 20px;
}

.featureItem-header-wrapper {
  display: flex;
  flex-direction: row;
  margin: 40px;
  justify-content: space-between;
  align-items: center;
}

.featureItem-titleImage-container {
  height: 100px;
  width: 100px;
  overflow: hidden;
  display: flex;
  border: 8px solid #3e2a94;
  border-radius: 40px;
}

.featureItem-titleImage {
  height: 100px;
  position: relative;
  left: -16%;
}

.featureItem-title {
  color: #00ff85;
  font-size: 24px;
  width: 50%;
  margin-left: 4px;
}

.featureItem-content {
  white-space: pre-wrap;
  color: #312272;
  margin-left: 40px;
  margin-right: 40px;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
}

@media only screen and (min-width: 768px) {
  .featureItem {
    width: 330px;
    margin-left: 0px;
    margin-right: 0px;
  }
  
  .featureItem:first-child {
    width: 700px;
  }
}

@media only screen and (min-width: 1400px) {
  .featureItem-title {
    font-size: 29px;
  }

  .featureItem-content {
    font-size: 18px;
  }
}