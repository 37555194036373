body {
  margin: 0;
  font-family: 'Lota Grotesque', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3e2a94;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Lota Grotesque;
  src:
    local('Black Italic'), url(/static/media/Black-Italic.dc563c5f.otf) format('opentype'), font-type
    local('Black'), url(/static/media/Black.02492135.otf) format('opentype'),
    local('Bold Italic'), url(/static/media/Bold-Italic.053fc915.otf) format('opentype'),
    local('Bold'), url(/static/media/Bold.979ee3a7.otf) format('opentype'),
    local('Extra Light Italic'), url(/static/media/Extra-Light-Italic.c0df7b0c.otf) format('opentype'),
    local('Extra Light'), url(/static/media/Extra-Light.f0981bc9.otf) format('opentype'),
    local('Light Italic'), url(/static/media/Light-Italic.303f9018.otf) format('opentype'),
    local('Light'), url(/static/media/Light.2eba7a72.otf) format('opentype'),
    local('Regular Italic'), url(/static/media/Regular-Italic.d8c8648f.otf) format('opentype'),
    local('Regular'), url(/static/media/Regular.356a3dd5.otf) format('opentype'),
    local('Semi Bold Italic'), url(/static/media/Semi-Bold-Italic.893c728e.otf) format('opentype'),
    local('Semi Bold'), url(/static/media/Semi-Bold.eec0d6e2.otf) format('opentype'),
    local('Thin Italic'), url(/static/media/Thin-Italic.f8531f84.otf) format('opentype'),
    local('Thin'), url(/static/media/Thin.e1801c4d.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.App {
  text-align: center;
  background-color: #3e2a94;
  overflow: hidden;
}

a {
  text-decoration: none;
}

a:visited {
  color: #FFFFFF;
}

a:hover {
  color: #E6B2F3;
}

.social {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.social-hero {
  width: 200px;
}

.social-image {
  width: 11vw;
  height: 11vw;
  max-width: 60px;
  max-height: 60px;
  border-radius: 3px;
}

.social-link-bar {
  margin: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: auto;
}

.social-link {
  width: 100%;
  overflow: hidden;
  max-width: 120px;
  max-height: 60px;
}

.social-title {
  color: #FFFFFF;
  font-size: 22px;
  font-style: italic;
  font-weight: 800;
}

@media only screen and (min-width: 580px) {
  .social-link-bar {
    justify-content: center;
  }
}
.contact {
  display: flex;
  flex-direction: column;
  align-content: left;
  background-color: #FFFFFF;
  margin-bottom: 5vw;
  padding: 20px 20px 20px 20px;
  border-radius: 20px;
  font-size: 17px;
  font-weight: 800;
}

.contact-title {
  font-size: 23px;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 80%;
  align-self: center;
}

.contact-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
}

.contact-instructions {
  line-height: 1.8em;
  text-align: left;
  font-weight: 500;
  font-size: 17px;
}

.contact-label {
  font-weight: 500;
}

.contact-image {
  height: 54px;
}

.contact-input {
  width: 98%;
  background-color: #FFFFFF;
  height: 60px;
  border: 1px solid black;
  border-radius: 14px;
  margin: 10px 0px 20px 0px;
}

.contact-input:hover {
  background-color: #a26bff;
}

.contact-submit {
  background-color: #312272;
  color: #FFFFFF;
  width: 100%;
  height: 60px;
  font-size: 17px;
  font-weight: 800;
  border-radius: 14px;
}

.contact-submit:hover {
  background-color: #a26bff;
}

@media only screen and (min-width: 768px) {
  .contact {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width:1026px) {
  .contact {
    margin-bottom: 0px;
    margin-right: 40px;
    width: 50%
  }

  .contact-social-wrapper {
    margin-top: auto;
  }
}

@media only screen and (min-width: 1400px) {
  .contact-title {
    font-size: 36px;
  }

  .contact-instructions {
    font-size: 18px;
  }

  .contact-form {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) {
  .features {
    margin-top: -40px;
  }

  .features-featureItem-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}
.featureItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  margin: 20px auto;
  width: 90vw;
  border-radius: 20px;
}

.featureItem-header-wrapper {
  display: flex;
  flex-direction: row;
  margin: 40px;
  justify-content: space-between;
  align-items: center;
}

.featureItem-titleImage-container {
  height: 100px;
  width: 100px;
  overflow: hidden;
  display: flex;
  border: 8px solid #3e2a94;
  border-radius: 40px;
}

.featureItem-titleImage {
  height: 100px;
  position: relative;
  left: -16%;
}

.featureItem-title {
  color: #00ff85;
  font-size: 24px;
  width: 50%;
  margin-left: 4px;
}

.featureItem-content {
  white-space: pre-wrap;
  color: #312272;
  margin-left: 40px;
  margin-right: 40px;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
}

@media only screen and (min-width: 768px) {
  .featureItem {
    width: 330px;
    margin-left: 0px;
    margin-right: 0px;
  }
  
  .featureItem:first-child {
    width: 700px;
  }
}

@media only screen and (min-width: 1400px) {
  .featureItem-title {
    font-size: 29px;
  }

  .featureItem-content {
    font-size: 18px;
  }
}
.hero-image {
  background-image:  
    url(/static/media/Fam1.fc49b0f4.jpeg);
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}

.hero-text {
  text-align: left;
  position: relative;
  top: -5%;
  margin-left: 20px;
  color: #FFFFFF;
  width: 70vw;
  font-size: 69px;
}

.hero-text-h1 {
  font-size: 48px;
  line-height: 1.2em;
  margin-top: 0px;
}

.hero-text-h1-primary {
  color: #FFFFFF;
}

.hero-text-h1-emphasis {
  color: #00FF85;
}

.hero-button {
  border: 0;
  background: transparent;
}

.hero-cta-image {
  width: 40px;
}

.hero-cta-text {
  width: 224px;
  height: 70px;
  background-color: #312272;
  color: #FFFFFF;
  font-size: 15px;
  letter-spacing: 0.25em;
  font-weight: 800;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 35px;
  padding-left: 20px;
}

.hero-cta {
  display:flex;
  flex-direction: row;
  align-items: flex-end;
}

@media only screen and (min-width: 768px) {
  .hero-image {
    height: 82vh;
    align-items: flex-start;
  }

  .hero-text {
    top: 5%;
    margin-left: 50px;
    margin-right: 50px;
    width: 85%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-size: 36px;
  }

  .hero-cta {
    margin-top: 9px;
  }

  .hero-text-h1 {
    margin-bottom: 0px;
    width: 62%;
    font-size: 36px;
  }
}

@media only screen and (min-width: 1026px) {
  .hero-text-h1 {
    width: 43%;
    font-size: 69px;
  }
}

@media only screen and (min-width: 1166px) {
  .hero-text-h1 {
    width: 68%;
  }

  .hero-text {
    margin-left: 6vw;
  }
}

@media only screen and (min-width: 1275px) {
  .hero-text-h1 {
    width: 62%;
  }
}

@media only screen and (min-width: 1391px) {
  .hero-text-h1 {
    width: 57%;
  }
}

@media only screen and (min-width: 1522px) {
  .hero-text-h1 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .hero-text {
    justify-content: space-evenly;
    width: 1454px;
    margin-left: auto;
    margin-right: auto;
    top: 15%;
  }
}
.news {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 20px auto;
}

@media only screen and (min-width: 768px) {
  .news {
    flex-wrap: nowrap;
    position: relative;
    top: -12vh;
    height: 22vh;
    width: 86vw;
    margin-left: 7vw;
  }
}

@media only screen and (min-width: 1026px) {
  .news {
    top: -17vh;
  }
}

@media only screen and (min-width: 1166px) {
  .news {
    top: -21vh;
  }
}

@media only screen and (min-width: 1324px) {
  .news {
    top: -23vh;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1417px) {
  .news {
    width: 1134px;
    top: -13vw;
  }
}

@media only screen and (min-width: 1417px) {
  .news {
    top: -20vh;
  }
}
.news-item {
  width: 43vw;
  height: 43vw;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 7px 7px 10px rgb(162 107 255 / 40%);
}

.news-item-img {
  position: inherit;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.news-item-headline {
  position: relative;
  bottom: 34%;
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 800;
  z-index: 2;
}

@media only screen and (min-width: 768px) {
  .news-item {
    width: 18vw;
    height: 22vw;
  }
}

@media only screen and (min-width: 1166px) {
  .news-item-headline {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1324px) {
  .news-item {
    height: 291px;
    width: 238px;
  }
}
.product {
  color: #FFFFFF;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.product-content {
  font-size: 16px;
  line-height: 1.8em;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  width: 90vw;
  font-weight: 600;
}

.product-content-emphasis {
  color: #00FF85;
}

.product-hr {
  height: 4px;
  width: 90vw;
  background-color: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
}

.product-image {
  height: 60vh;
  object-fit: cover;
  object-position: center;
}

.product-title {
  font-size: 26px;
}

@media only screen and (min-width: 768px) {
  .product-content {
    max-width: 700px;
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .product {
    margin-top: calc(-190px + 11vw);
  }
}

@media only screen and (min-width: 1166px) and (orientation: portrait) {
  .product {
    margin-top: calc(-240px + 11vw);
  }
}

@media only screen and (min-width: 1498px) {
  .product {
    margin-top: -70px;
  }

  .product-content {
    font-size: 18px;
  }

  .product-title {
    font-size: 50px;
  }
}

.sponsors {
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sponsors-cta {
  color: #00FFC0;
  background-color: #2F0549;
  padding: 20px;
  font-weight: 600;
  font-size: 15px;
}

.sponsors-cta:hover {
  color: #312272;
  background-color: #A26BFF;
}

.sponsors-cta-link {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: auto;
  bottom: 0px;
}

.sponsors-hr {
  width: 90%;
  background-color: #FFFFFF;
  margin: 8px 0px 8px 0px;
  margin-left: auto;
  margin-right: auto;
  height: 3px;
}

.sponsors-link {
  height: 60px;
  margin: 15px 0px 15px 0px;
}

.sponsor-logo {
  height: 60px;
}

.sponsors-logos-group {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0px 20px 0px 20px;
  padding-bottom: 10px;
}

.sponsors-title {
  color: #00FFC0;
  font-size: 21px;
  font-weight: 800;
  margin: 20px auto;
}

@media only screen and (min-width: 768px) {
  .sponsors-logos-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1300px;
  }

  .sponsors-link {
    margin: 15px 15px 15px 15px;
  }
}

@media only screen and (min-width: 1400px) {
  .sponsors-title {
    font-size: 30px;
  }
}
.store {
  background-color: #FFFFFF;
  padding: 20px 20px 20px 20px;
  border-radius: 20px;
  font-size: 17px;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.store-title {
  font-size: 19px;
  font-style: italic;
  font-weight: 800;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  align-self: center;
  color: #3e2a94;
}

.store-cta {
  width: 100%;
  line-height: 1.4em;
  font-weight: 800;
  font-size: 19px;
  color: #3e2a94;
  margin-top: 0px;
  margin-bottom: 10px;
}

.store-image {
  width: 100%;
  margin: 20px;
  margin-left: 0px;
  align-self: center;
}

.store-link {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.store-link-content {
  background-color: #312272;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 40%;
}

.store-link-content:hover {
  background-color: #a26bff;
}

@media only screen and (min-width:1026px) {
  .store {
    width: 50%;
  }
}

@media only screen and (min-width: 1400) {
  .store-title {
    font-size: 24px;
  }

  .store-cta {
    font-size: 25px;
  }

  .store-link-content {
    font-size: 18px;
  }
}
.team {
  background-image: url(/static/media/TeamBackground.bc3173c8.jpeg);
  padding-top: 4px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-attachment: fixed;
  color: #FFFFFF;
}

.team-intro {
  margin-left: 20px;
  margin-right: 20px;
  background-color: rgba( 0, 0, 0, 0.5);
  box-shadow: 0px 11px 7px 3px rgba( 0, 0, 0, 0.5); 
  padding: 16px;
}

.team-intro-title {
  font-size: 26px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.team-intro-title-emphasis {
  color: #9780f9;
}

.team-emphasis {
  color: #00FFC0;
}

.team-intro-primary {
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 20px;
}

.team-intro-secondary {
  font-weight: 500;
  text-align: left;
}

.team-hr {
  width: 90%;
  background-color: #FFFFFF;
  margin: 8px 0px 8px 0px;
  margin-left: auto;
  margin-right: auto;
  height: 3px;
}

@media only screen and (min-width: 768px) {
  .team-intro {
    margin-left: auto;
    margin-right: auto;
    width: 520px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .team-intro-title {
    font-size: 42px;
  }

  .team-intro-primary {
    font-size: 30px;
  }

  .team-intro-secondary {
    font-size: 22px;
  }

  .team-members {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
}

@media only screen and (min-width: 1026px) {
  .team-members {
    width: 1026px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1400px) {
  .team-intro-title {
    font-size: 50px;
  }

  .team-intro-primary {
    font-size: 25px;
  }

  .team-intro-secondary {
    font-size: 16px;
  }
}
.teamBio {
  margin: 20px 20px 20px 20px auto;
}

.teamBio-img {
  height: 200px;
  border-radius: 200px;
  margin-top: 40px;
}

.teamBio-teamMember {
  display: flex;
  flex-direction: column;
  background-color: #312272;
  padding: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 8px 8px 20px 20px;
  margin: auto;
  margin-top: 20px;
}

.teamBio-teamMember-name {
  color: #FFFFFF;
  font-size: 26px;
}

.teamBio-teamMember-title {
  color: #FFFFFF;
  font-size: 21px;
}

.teamBio-content {
  color: #FFFFFF;
  margin-left: 20px;
  margin-right: 20px;
}

@media only screen and (min-width: 768px) {
  .teamBio-teamMember {
    margin-top: 30px;
  }
}
.vision {
  color: #FFFFFF;
}

.vision-text {
  font-size: 16px;
  text-align: center;
  line-height: 1.8em;
  font-weight: 600;
  margin: 0px 20px 20px 20px;
}

.vision-text-emphasis {
  color: #00FF85;
}

.vision-title {
  font-size: 26px;
  margin: 20px 20px 20px 20px;
}

.vision-image {
  height: 66vw;
  width: 90vw;
  margin: 20px 20px 20px 20px;
  border: 1px #A26BFF solid;
  border-radius: 20px;
  object-fit: cover;
}

.vision-link {
  display: flex;
  justify-content: space-evenly;
}

.vision-mascot {
  height: 220px;
  position: relative;
  bottom: 110px;
}

.vision-hr {
  width: 90%;
  background-color: #FFFFFF;
  margin: 8px 0px 8px 0px;
  margin-left: auto;
  margin-right: auto;
  height: 3px; 
}

@media only screen and (min-width: 768px) {
  .vision {
    position: relative;
  }
  
  .vision-title {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .vision-text {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .vision-image {
    max-width: 700px;
    max-height: 400px;
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  .vision {
    top: calc(-15em + 15vw);
  }
}

@media only screen and (min-width: 1026px) and (orientation: portrait) {
  .vision {
    top: calc(-15em + 15vw);
  }
}

@media only screen and (min-width: 1166px) and (orientation: portrait) {
  .vision {
    top: calc(-18em + 15vw);
  }
}

@media only screen and (min-width: 1324px) and (orientation: portrait) {
  .vision {
    top: calc(-21em + 18vw);
  }
}

@media only screen and (min-width: 1498px) {
  .vision {
    top: -75px;
  }

  .vision-title {
    font-size: 50px;
  }

  .vision-text {
    font-size: 18px;
  }
}

.main {
  padding-top: 94px;
}

.multimedia {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1040px;
}

@media only screen and (min-width: 1026px) {
  .multimedia {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.glossary {
  padding-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 40px;
  margin-right: 40px;
  color: #FFFFFF;
}

.glossary p {
  text-align: left;
  text-align: initial;
  margin-top: 0px;
}

.glossary h2 {
  margin-bottom: 8px;
}
.privacy {
  padding-top: 94px;
  background-color: #FFFFFF;
}

.iframe {
  width: 100%;
}
.terms {
  padding-top: 94px;
  background-color: #FFFFFF;
}

.iframe {
  width: 100%;
}
.share {
  padding-top: 94px;
  color: #FFFFFF;
  margin: 40px;
  height: 46vh;
}

.share-content {
    font-size: 22px;
}
.header-wrapper {
  position: fixed;
  z-index: 10;
  height: 94px;
  width: 100%;
  background: linear-gradient(rgba(62, 42, 148, 1),rgba(62, 42, 148, 0));
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-logo {
  overflow: hidden;
  background-color: #312272;
  color: #FFFFFF;
  padding: 0px 10px 4px 10px;
  border-radius: 0px 0px 20px 20px;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  height: 74px;
}

.header-logo-image {
  height: 38px
}

.header-brand-wrapper {
  margin-left: 10px;
}

.header-brand-primary {
  font-size: 40px;
}

.header-brand-secondary {
  font-size: 9px;
}

.header-menu {
  height: 80px;
  width: 80px;
}

.header-menu-button {
  background-color: #3e2a94;
  border-width: 0px;
  bottom: 4px;
  padding: 0px;
  position: relative;
  border-radius: 0px 0px 0px 60px;
}

.header-menu-hash-link {
  color: #FFFFFF;
}

.header-menu-hash-link:hover {
  color: #E6B2F3;
}

@media only screen and (min-width: 1026px) {
  .header-menu {
    width: 720px;
    background-color: #312272;
    height: 84px;
    color: #FFFFFF;
    padding: 0px 10px 4px 10px;
    border-radius: 0px 0px 20px 20px;
  }

  .header-menu-link-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    height: 100%;
    align-items: center;
  }

  .header-menu-hash-link {
    font-size: 20px;
    font-weight: 500;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    height: inherit;
  }
}

@media only screen and (min-width: 1166px) {
  .header-wrapper {
    justify-content: space-around;
  }
}
.footer {
  background-color: #3e2a94;
  color: #FFFFFF;
  padding: 26px 0px;
  padding-bottom: 80px;
}

.footer a:visited {
  color: #FFFFFF;
}

.footer a:hover {
  color: #E6B2F3;
}

.footer-hr {
  width: 90%;
  background-color: #FFFFFF;
  margin: 0px 0px 8px 0px;
  margin-left: auto;
  margin-right: auto;
  height: 3px;
}

.footer-contact {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 22px;
}

.footer-contact-title {
  font-weight: 800;
  text-align: left;
  font-size: 18px;
}

.footer-contact-links {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  font-weight: 500;
}

.footer-contact-links-a {
  text-align: left;
  font-size: 15px;
  margin-top: 10px;
  color: #FFFFFF;
}

.footer-contact-links-a:hover {
  color: #E6B2F3;
}

.footer-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  font-size: 22px;
  font-weight: 500;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.footer-menu-navigation {
  font-weight: inherit;
}

.footer-menu-title {
  font-weight: 800;
  text-align: left;
  font-size: 18px;
  display: flex;
  flex-direction: column;
}

.footer-menu-links {
  text-align: left;
  margin-top: 10px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
}

.footer-menu-socials {
  margin-left: auto;
  margin-right: auto;
}

.footer-legal {
  text-align: left;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-size: 11px;
  margin-top: 20px;
  line-height: 1.4em;
}

.footer-legal-subscribe {
  width: 73%;
}

.footer-legal-content {
  width: 72%
}

.footer-legal-content-links {
  color: #FFFFFF;
}

.footer-lesser {
  height: 1px;
}

@media only screen and (min-width: 768px) {
  .footer-wrapper {
    width: 90%;
    margin: auto;
    display: flex;
    max-width: 1040px;
  }

  .footer-contact {
    width: 34%;
  }

  .footer-menu {
    width: 66%;
    align-items: stretch;
    margin-top: 20px;
  }

  .footer-menu-navigation {
    margin-left: auto;
    margin-right: auto;
  }

  .footer-menu-socials {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 1400px) {
  .footer-contact-title {
    font-size: 21px;
  }

  .footer-menu-title {
    font-size: 21px;
  }

  .footer-contact-links-a {
    font-size: 16px;
  }

  .footer-menu-links {
    font-size: 16px;
  }

  .footer-legal {
    font-size: 12px;
  }
}
